import { useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { waitForTealium } from 'utils/analytics'

/**
 * Hook that tracks an event after the page loads and Analytics context data is set
 * @param {Object} event
 */
const useTrackLoadEvent = (event) => {
  const {
    state: { trackingData, trackPageComplete },
  } = useContext(AnalyticsContext)
  const router = useRouter()
  const [done, setDone] = useState(false)
  const { fireAndForget } = useTriggerEvents()
  useEffect(() => {
    if (done) return

    // Check if the page has loaded, the page has been tracked, and the tracking data has been updated as a safety check
    const isUpdatedTrackingData = router.asPath.includes(trackingData?.pagePath)
    if (trackPageComplete && isUpdatedTrackingData) {
      waitForTealium(() => {
        fireAndForget({ ...event })
        setDone(true)
      })
    }
    // We only want trackPageComplete to re-render because we know when trackPageComplete
    // is true that means the page has loaded and a page load has fired off with current trackingData state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackPageComplete, done])
}

export { useTrackLoadEvent }
